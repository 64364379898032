<template>
  <div class="">
    <a-modal :maskClosable="false" title="操作" style="top: 8px" width="85%" v-model="visible" :footer="null">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="采购订单">
          <a-form-model ref="form" :model="rowData" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
            <div class="title">采购订单</div>
            <a-row :gutter="4">
              <a-col :md="8">
                <a-form-model-item class="space_between" label="采购订单编号">{{ rowData.purchaseCode }}</a-form-model-item>
              </a-col>
              <a-col :md="8">
                <a-form-model-item class="space_between" label="发货单编号">{{ rowData.shipCode }}</a-form-model-item>
              </a-col>

              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="期望到货时间">-->
              <!--                  {{ rowData.expectTime || '-&#45;&#45;' }}-->
              <!--                </a-form-model-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="收货人">{{ rowData.consignee }}</a-form-model-item>-->
              <!--              </a-col>-->
              <!--            </a-row>-->
              <!--            <a-row :gutter="4">-->
              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="联系电话">{{ rowData.phone }}</a-form-model-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="收货地址">-->
              <!--                  {{ rowData.provinceName }}{{ rowData.cityName }}{{ rowData.areaName }}{{ rowData.address }}-->
              <!--                </a-form-model-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8">-->
              <!--                <a-form-model-item class="space_between" label="订单备注">-->
              <!--                  {{ !rowData.orderRemark ? '暂无' : rowData.orderRemark }}-->
              <!--                </a-form-model-item>-->
              <!--              </a-col>-->
            </a-row>
            <div class="title">商品信息</div>
            <a-table :data-source="tableData" :rowKey="(record) => record.productId" :pagination="false" bordered>
              <!-- <a-table-column title="商品名称" data-index="name" align="center"></a-table-column>
              <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
              <a-table-column title="规格" data-index="specStr" align="center"></a-table-column>
              <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
              <a-table-column title="建议零售价" data-index="sellPrice" align="center">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="数量" data-index="shipNum" align="center" :width="120"></a-table-column> -->
              <!-- <a-table-column title="单位" data-index="proUnit" align="center" :width="150"></a-table-column> -->
              <a-table-column title="订单编号" data-index="purchaseCode" align="center"></a-table-column>
              <a-table-column title="商品名称" data-index="name" align="center"></a-table-column>
              <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
              <a-table-column title="规格" data-index="specStr" align="center"></a-table-column>
              <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
              <!-- <a-table-column title="建议零售价" data-index="sellPrice" align="center">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column> -->
              <a-table-column title="数量" data-index="shipNum" align="center" :width="120"></a-table-column>
              <a-table-column title="型号" data-index="barCode" align="center" :width="120"></a-table-column>
              <a-table-column title="商家编码" data-index="sellerCode" align="center" :width="120"></a-table-column>
              <a-table-column title="物料编码" data-index="goodsCode" align="center" :width="120"></a-table-column>
              <a-table-column title="发货数量" data-index="shipNum" align="center" :width="120"></a-table-column>
            </a-table>
            <div style="text-align: right; margin-top: 14px" v-if="handle != 'check'">
              <a-button type="default" @click="toCancel">取消</a-button>
              <a-button type="primary" @click="toSubmit">确认</a-button>
            </div>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
      <!-- <div class="footer_btns">
        <a-button type="default">取消</a-button>
        <a-button type="primary" >确定</a-button>
      </div> -->
    </a-modal>
  </div>
</template>

<script>
import {editOrderPurchaseOrder, selectByIdOrderPurchaseOrder, addOrderPurchaseOrder} from '../api/OrderPurchaseOrderApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      tableData: [],

    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true

      selectByIdOrderPurchaseOrder(row.id).then((res) => {
        this.rowData = res.body
        this.tableData = res.body.details
      })
    },

    // 关闭弹框
    toCancel() {
      this.visible = false
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        const res = this.handle === 'add' ? await addOrderPurchaseOrder(this.rowData) : await editOrderPurchaseOrder(this.rowData)
        if (res.code === 200) {
          this.$notification.success({message: res.message})
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({message: res.message})
        }
      })
    }
  },
  created() {

  },

}
</script>

<style lang="less" scoped>

</style>
